import { ApiErrors, del as httpDel, patch, post, put } from '@app/helpers/api';
import { Component, Overrides } from '@app/models/component';
import { Panel, Definition, WritePanel, Errors } from '@app/models/panel';
import {
  addComponent as addComponentAction,
  changeDiscount,
  clearBuilder,
  clearComponents as clearComponentsAction,
  replaceComponent as replaceComponentAction,
  createError,
  createRequest,
  createSuccess,
  deleteRequest,
  deleteSuccess,
  modifyComponent as modifyComponentAction,
  removeComponent as removeComponentAction,
  setWorkingRequest,
  setWorkingSuccess,
  updateError,
  updateLookups as updateLookupsAction,
  updateRequest,
  updateSuccess,
  changeGPOCenterSuccess,
  changeGPOCenterRequest,
} from './actions';
import { error, rehydrate, last } from '@bespohk/lib';

import { Dispatch } from 'redux';
import { Lookups, fetch as fetchLookups } from '@app/services/lookups';
import { PanelModifier } from './types';
import { PanelSet } from '@app/models/panel-set';
import PanelType from '@app/models/panel-type';
import { Project } from '@app/models/project';
import Series from '@app/models/series';
import { operations as alertOperations } from '../alerts/operations'; // eslint-disable-line
import { generate } from '@app/services/panel-set';
import { operations as projectOperations } from '../project/operations'; // eslint-disable-line
import { fetchSuccess } from '../project/actions'; // eslint-disable-line
import PlateFinish from '@app/models/plate-finish';
import { isPan } from '@app/helpers/components';
import { ServiceType } from '@app/models/type';
import objectHash from '@app/helpers/object-hash';

const { add } = alertOperations;

// Panel operations

const setWorking =
  (panel: Panel, modifier: PanelModifier = null): any =>
  async (dispatch: Dispatch<any>) => {
    if (panel) {
      add('info', `Switched to ${panel.mspReference}`, 3)(dispatch);
    }
    dispatch(setWorkingRequest());

    const lookups = await fetchLookups();

    dispatch(updateLookupsAction(lookups));
    dispatch(setWorkingSuccess(panel, modifier));
  };

const createXCodeHash = (data: WritePanel): string => {
  const hashes = (data.components || []).reduce(
    (p, c) => {
      const lastIndex = p.length - 1;
      if (p[lastIndex].length === 0 && p.length > 0) {
        p[lastIndex].push('|'); // First component after a hbar
      }
      p[lastIndex].push(c);
      if (c.showHbar) {
        p[lastIndex].push('|'); // Last component with hbar
        p.push([]);
      }

      return p;
    },
    [[]],
  );

  const objHashes = hashes.map((hash) =>
    objectHash({
      plateFinish: data.plateFinish,
      panelType: data.panelType,
      ...hash,
    }),
  );

  return objHashes.join('#');
};

const create =
  (data: WritePanel, project: Project, lookups: Lookups): any =>
  (dispatch: Dispatch<any>) => {
    data.xcodeHash = createXCodeHash(data);
    dispatch(createRequest(data));

    return post(`/projects/${data.project}/panels`, data, Definition)
      .then((panel: Definition) => {
        add(
          'success',
          `Created ${panel.mspReference} successfully.`,
          10,
        )(dispatch);
        const newPanel: Panel = rehydrate(Panel, panel);
        const panelSet: PanelSet = generate(newPanel, lookups);
        projectOperations.addPanel(project, newPanel, panelSet)(dispatch);
        dispatch(createSuccess(panel));

        return panel;
      })
      .catch((errors: ApiErrors) => {
        error(errors);
        dispatch(createError());
        throw errors;
      });
  };

const patchProject = (
  project: Project,
  panel: Definition,
  lookups: Lookups,
) => {
  const updatedPanel = rehydrate(Panel, panel);
  const panelSet: PanelSet = generate(updatedPanel, lookups);
  const index = project.panelSets.findIndex(
    (panelSet_) => panelSet_.panel.uuid === panelSet.panel.uuid,
  );
  project.panelSets[index] = panelSet;

  return [updatedPanel, project];
};

const update =
  (panel: Panel, data: WritePanel, project: Project, lookups: Lookups): any =>
  (dispatch: Dispatch<any>) => {
    // Required as <Form> component does not internally update components
    data.xcodeHash = createXCodeHash(data);
    data.components = panel.components as any;
    dispatch(updateRequest(data));

    return put(
      `/projects/${data.project}/panels/${panel.uuid}`,
      data,
      Definition,
    )
      .then((p: Definition) => {
        add('success', `Updated ${p.mspReference} successfully.`, 4)(dispatch);
        const [updatedPanel, project_] = patchProject(project, p, lookups);
        dispatch(fetchSuccess(project_));
        dispatch(updateSuccess(updatedPanel, project));

        return p;
      })
      .catch((errors: ApiErrors) => {
        error(errors);
        dispatch(createError());
        add(
          'error',
          {
            title: 'Update Error',
            body: `Unable to update ${panel.mspReference}, please correct the errors and try again.`,
          },
          4,
        )(dispatch);
        dispatch(updateError());
        throw errors;
      });
  };

const del =
  (project: Project, panel: Panel): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(deleteRequest(project, panel));

    return httpDel(`/projects/${project.uuid}/panels/${panel.uuid}`)
      .then(() => {
        add(
          'success',
          `Deleted ${panel.mspReference} from ${project.name} successfully.`,
          4,
        )(dispatch);
        dispatch(deleteSuccess(project, panel));
        projectOperations.deletePanel(project, panel)(dispatch);
      })
      .catch((errors: ApiErrors) => {
        error(errors);
        throw errors;
      });
  };

const updateDiscount =
  (project: Project, panel: Panel, discount: number, lookups: Lookups): any =>
  (dispatch: Dispatch<any>) => {
    return patch(`/projects/${project.uuid}/panels/${panel.uuid}`, {
      discount,
    })
      .then((p) => {
        add(
          'success',
          `Updated discount on ${p.mspReference} to ${discount}%`,
          4,
        )(dispatch);
        const project_ = patchProject(project, panel, lookups)[1];
        dispatch(fetchSuccess(project_));
        dispatch(changeDiscount(discount));
      })
      .catch((errors: ApiErrors) => {
        add(
          'error',
          `Failed to update discount on ${panel.mspReference} to ${discount}%, please try again.`,
          4,
        )(dispatch);
        error(errors);
        throw errors;
      });
  };

const clear = (): any => (dispatch: Dispatch<any>) => {
  dispatch(clearBuilder());
};

const validate =
  (panelSet: PanelSet, lookups: Lookups) => (dispatch: Dispatch<any>) => {
    const backplateErrors = panelSet.backplate.errors();
    const panelErrors = panelSet.panel.errors(lookups);

    const { serviceTypes } = panelSet.panel;
    const lastServiceType: ServiceType | undefined = last(serviceTypes);

    const lastComponent: Component | undefined = lastServiceType
      ? last(lastServiceType.components)
      : undefined;

    if (lastServiceType && isPan(lastComponent, panelSet.panel.series)) {
      const previousSibling =
        lastServiceType.components[lastServiceType.components.length - 2];
      if (
        previousSibling &&
        !previousSibling.isGpo &&
        !(lastServiceType.name === 'LV')
      ) {
        panelErrors.push(Errors.pan);
      }
    }

    const panWallboxError = "GPO with Neon can't be in it's own wallbox";
    const hasGpoInOwnWallbox = panelSet.backplate.wallboxes.find(
      (wallbox) =>
        wallbox.components.length === 1 &&
        isPan(wallbox.components[0], panelSet.panel.series),
    );
    if (hasGpoInOwnWallbox) {
      panelErrors.push(panWallboxError);
    }

    const errors = [...panelErrors, ...backplateErrors];

    if (errors.length) {
      add('error', errors[0], 10)(dispatch);

      return Promise.reject(errors);
    } else {
      return Promise.resolve(true);
    }
  };

const changeGPOCenter =
  (center: number, components: string[]) => (dispatch: Dispatch<any>) => {
    dispatch(changeGPOCenterRequest(center, components));

    return (
      post(`/components/matching-center/${center}`, components, Component)
        .then((matched) => {
          dispatch(changeGPOCenterSuccess(components, matched));
        })
        // eslint-disable-next-line
        .catch(() => {})
    );
  };

// Component operations

const addComponent =
  (
    component: Component,
    series: Series,
    panelType: PanelType,
    plateFinish: PlateFinish,
    index?: number,
    overrides?: Overrides,
  ): any =>
  (dispatch: Dispatch<any>): any =>
    dispatch(
      addComponentAction(
        component,
        series,
        panelType,
        plateFinish,
        index,
        overrides,
      ),
    );

const removeComponent =
  (index: number): any =>
  (dispatch: Dispatch<any>) =>
    dispatch(removeComponentAction(index));

const clearComponents = (): any => (dispatch: Dispatch<any>) =>
  dispatch(clearComponentsAction());

const modifyComponent =
  (
    component: Component,
    index: number,
    newIndex: number,
    overrides: Overrides,
  ): any =>
  (dispatch: Dispatch<any>) =>
    dispatch(modifyComponentAction(component, index, newIndex, overrides));

const replaceComponent =
  (
    component: Component,
    index: number,
    series: Series,
    panelType: PanelType,
    plateFinish: PlateFinish,
  ): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(
      replaceComponentAction(component, series, panelType, plateFinish, index),
    );
    // dispatch(removeComponentAction(index));
    // dispatch(
    //   addComponentAction(component, series, panelType, plateFinish, index),
    // );
  };

const updateLookups = () => async (dispatch: Dispatch<any>) => {
  const lookups = await fetchLookups();

  dispatch(updateLookupsAction(lookups));
};

type Operations = {
  setWorking: (panel: Panel, modifier?: PanelModifier) => void;
  addComponent: (
    component: Component,
    series: Series,
    panelType: PanelType,
    plateFinish: PlateFinish,
    index?: number,
    overrides?: Overrides,
  ) => void;
  removeComponent: (index: number) => void;
  clearComponents: () => void;
  modifyComponent: (
    component: Component,
    index: number,
    newIndex: number,
    overrides: Overrides,
  ) => void;
  replaceComponent: (
    component: Component,
    index: number,
    series: Series,
    panelType: PanelType,
    plateFinish: PlateFinish,
  ) => void;
  create: (
    data: WritePanel,
    project: Project,
    lookups: Lookups,
  ) => Promise<any>;
  update: (
    panel: Panel,
    data: WritePanel,
    project: Project,
    lookups: Lookups,
  ) => Promise<any>;
  del: (project: Project, panel: Panel) => Promise<any>;
  updateDiscount: (
    project: Project,
    panel: Panel,
    discount: number,
    lookups: Lookups,
  ) => Promise<any>;
  clear: () => void;
  validate: (panel: PanelSet, lookups: Lookups) => any;
  changeGPOCenter: (center: number, components: string[]) => any;
  updateLookups: () => any;
};

const operations: Operations = {
  setWorking,
  addComponent,
  removeComponent,
  clearComponents,
  modifyComponent,
  replaceComponent,
  create,
  update,
  del,
  updateDiscount,
  clear,
  validate,
  changeGPOCenter,
  updateLookups,
};

export { operations, Operations };
